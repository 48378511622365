import React from "react";
import { Carousel } from "flowbite-react";
// import First from "../assests/aboutUs/one.jpg";
// import Second from "../assests/aboutUs/two.jpg";
// import Third from "../assests/aboutUs/three.jpg";
// import Fourth from "../assests/aboutUs/four.jpg";
// import Five from "../assests/aboutUs/five.jpg";
// import Six from "../assests/aboutUs/six.jpg";
// import Seven from "../assests/aboutUs/seven.jpg";
// import Eight from "../assests/aboutUs/eight.jpg";
// import Nine from "../assests/aboutUs/nine.jpg";
// import Ten from "../assests/aboutUs/ten.jpg";
import AboutUsMobileEmblaCarousel from "./AboutUsMobileEmblaCarousel";

const AboutUsMobileCarousel = () => {
  return (
    <AboutUsMobileEmblaCarousel/>
    // <div className="h-[400px] w-full ">
    //   <Carousel slideInterval={3000} leftControl=" " rightControl=" ">
    //     <div className="flex w-full h-full ">
    //       <img
    //         src={First}
    //         className="object-contain w-full h-full"
    //         alt="Image_1"
    //       />
    //     </div>
    //     <div className="flex w-full h-full ">
    //       <img
    //         src={Second}
    //         className="object-contain w-full h-full"
    //         alt="Image_2"
    //       />
    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={Third}
    //         className="object-contain w-full h-full"
    //         alt="Image_3"
    //       />
    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={Fourth}
    //         className="object-contain w-full h-full"
    //         alt="Image_4"
    //       />
    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={Five}
    //         className="object-contain w-full h-full"
    //         alt="Image_4"
    //       />
    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={Six}
    //         className="object-contain w-full h-full"
    //         alt="Image_4"
    //       />
    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={Seven}
    //         className="object-contain w-full h-full"
    //         alt="Image_4"
    //       />
    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={Eight}
    //         className="object-contain w-full h-full"
    //         alt="Image_4"
    //       />
    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={Nine}
    //         className="object-contain w-full h-full"
    //         alt="Image_4"
    //       />
    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={Ten}
    //         className="object-contain w-full h-full"
    //         alt="Image_4"
    //       />
    //     </div>
    //   </Carousel>
    // </div>
  );
};

export default AboutUsMobileCarousel;
