import React from "react";

import MondayPic from "../assests/weekly-events/mon.webp"
import TuesdayPic from "../assests/weekly-events/tue.webp"
import WednesdayPic from "../assests/weekly-events/wed.webp"
import ThursdayPic from "../assests/weekly-events/thu.webp"
import FridayPic from "../assests/weekly-events/fri.webp"
import SaturdayPic from "../assests/weekly-events/sat.webp"
import SundayPic from "../assests/weekly-events/sun.webp"

const PhotoGallery = () => {
    const DayIntegerMapping =  {
        1: MondayPic,
        2: TuesdayPic,
        3: WednesdayPic,
        4: ThursdayPic,
        5: FridayPic,
        6: SaturdayPic,
        0: SundayPic
    }

    const todayInteger = new Date().getDay();
    const daysOfWeek = 7;

    return (
        <div class="ms-40 mx-40 mt-5 mb-5">
            <div class="grid grid-cols-4 gap-2 pt-5 md:pt-5">
                <div class="col-span-3 px-7">
                    <img class="h-auto w-full rounded-lg" src={DayIntegerMapping[todayInteger]} alt=""></img>
                </div>
                <div class="grid gap-4">
                    <div>
                        <img class="h-auto w-5/6 rounded-lg" src={DayIntegerMapping[(todayInteger + 1) % daysOfWeek]} alt=""></img>
                    </div>
                    <div>
                        <img class="h-auto w-5/6 rounded-lg" src={DayIntegerMapping[(todayInteger + 2) % daysOfWeek]} alt=""></img>
                    </div>
                    <div>
                        <img class="h-auto w-5/6 rounded-lg" src={DayIntegerMapping[(todayInteger + 3) % daysOfWeek]} alt=""></img>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-4 gap-4 ps-7 md:ps-7 pb-5 md:pb-5 pt-5 md:pt-5">
                <div>
                    <img class="h-auto w-5/6 rounded-lg" src={DayIntegerMapping[(todayInteger + 4) % daysOfWeek]} alt=""></img>
                </div>
                <div>
                    <img class="h-auto w-5/6 rounded-lg" src={DayIntegerMapping[(todayInteger + 5) % daysOfWeek]} alt=""></img>
                </div>
                <div>
                    <img class="h-auto w-5/6 rounded-lg" src={DayIntegerMapping[(todayInteger + 6) % daysOfWeek]} alt=""></img>
                </div>
                <div></div>
            </div>
        </div>

    );
};

export default PhotoGallery;
