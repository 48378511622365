import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'
import {
  NextButton,
  PrevButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import FirstPic from "../assests/aboutUs/one.jpg"
import SecondPic from "../assests/aboutUs/two.jpg"
import ThirdPic from "../assests/aboutUs/three.jpg"
import FourthPic from "../assests/aboutUs/four.jpg"
import FifthPic from "../assests/aboutUs/five.jpg"
import SixthPic from "../assests/aboutUs/six.jpg"
import SeventhPic from "../assests/aboutUs/seven.jpg"
import EigthPic from "../assests/aboutUs/eight.jpg"
import NinthPic from "../assests/aboutUs/nine.jpg"
import TenthPic from "../assests/aboutUs/ten.jpg"

const AboutUsMobileEmblaCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    AutoScroll({ playOnInit: true, stopOnInteraction: false, startDelay: 1000, stopOnFocusIn: false, speed: 1 })
  ])
  const [isPlaying, setIsPlaying] = useState(true)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const onButtonAutoplayClick = useCallback(
    (callback) => {
      const autoScroll = emblaApi?.plugins()?.autoScroll
      if (!autoScroll) return

      const resetOrStop =
        autoScroll.options.stopOnInteraction === false
          ? autoScroll.reset
          : autoScroll.stop

      resetOrStop()
      callback()
    },
    [emblaApi]
  )

  const toggleAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    const playOrStop = autoScroll.isPlaying()
      ? autoScroll.stop
      : autoScroll.play
    playOrStop()
  }, [emblaApi])

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    setIsPlaying(autoScroll.isPlaying())
    emblaApi
      .on('autoScroll:play', () => setIsPlaying(true))
      .on('autoScroll:stop', () => setIsPlaying(false))
      .on('reInit', () => setIsPlaying(autoScroll.isPlaying()))
  }, [emblaApi])

  return (
    <>
        <div className="embla mt-3">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    <div className="embla__slide" key={1}>
                        <div className="flex w-full h-full">
                            <img
                            src={FirstPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_1"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={2}>
                        <div className="flex w-full h-full ">
                            <img
                            src={SecondPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_2"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={3}>
                        <div className="flex  w-full h-full  ">
                            <img
                            src={ThirdPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_3"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={4}>
                        <div className="flex  w-full h-full  ">
                            <img
                            src={FourthPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_4"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={5}>
                        <div className="flex  w-full h-full  ">
                            <img
                            src={FifthPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_4"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={6}>
                        <div className="flex  w-full h-full  ">
                            <img
                            src={SixthPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_4"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={7}>
                        <div className="flex  w-full h-full  ">
                            <img
                            src={SeventhPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_4"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={8}>
                        <div className="flex  w-full h-full  ">
                            <img
                            src={EigthPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_4"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={9}>
                        <div className="flex  w-full h-full  ">
                            <img
                            src={NinthPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_4"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={10}>
                        <div className="flex  w-full h-full  ">
                            <img
                            src={TenthPic}
                            className="object-contain w-full h-full ps-2"
                            alt="Image_4"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="embla__controls">
                <div className="embla__buttons">
                <PrevButton
                    onClick={() => onButtonAutoplayClick(onPrevButtonClick)}
                />
                <NextButton
                    onClick={() => onButtonAutoplayClick(onNextButtonClick)}
                    disabled={nextBtnDisabled}
                />
                </div>

                {/* <button className="embla__play" onClick={toggleAutoplay} type="button">
                {isPlaying ? 'Stop' : 'Start'}
                </button> */}
            </div>
        </div>
    </>
  )
}

export default AboutUsMobileEmblaCarousel
