import React from "react";

import InstaTopLeftCorner from "../assests/instagram/i1.webp"
import InstaTopMiddle from "../assests/instagram/i2.webp"
import InstaTopRightCorner from "../assests/instagram/i3.webp"
import InstaBottomLeftCorner from "../assests/instagram/i4.webp"
import InstaBottomMiddle from "../assests/instagram/i5.webp"
import InstaBottomRightCorner from "../assests/instagram/i6.webp"

const InstagramSectionMobile = () => {
    const instaImages = [
        InstaTopLeftCorner,
        InstaTopMiddle,
        InstaTopRightCorner,
        InstaBottomLeftCorner,
        InstaBottomMiddle,
        InstaBottomRightCorner
    ]
    const instaLinks = [
        "https://www.instagram.com/p/C-SnRNlyejR/",
        "https://www.instagram.com/p/C9PbHjQy6WE/",
        "https://www.instagram.com/p/C-SnKftyAYR/",
        "https://www.instagram.com/p/C9pUK7nSa1-/",
        "https://www.instagram.com/p/C-SoSBNSebG/",
        "https://www.instagram.com/p/C96mlFCSZKG/",
    ]

    return (
        <div class="ms-2 mx-2 mt-5 bg-gray-900 rounded-lg">
            <div className="pt-3 px-7 mt-2 md:px-4 text-[22px] leading-[32px] md:leading-none md:text-[30px] text-white font-cormo uppercase font-extrabold text-left md:text-center md:mt-4">
                Follow us on Instagram
            </div>
            <div class="grid grid-cols-2 ps-7 md:ps-7 pb-5 md:pb-5 pt-5 md:pt-5">
                <div className="w-5/6">
                    <a target='_blank' href={instaLinks[0]}><img class="h-auto rounded-lg" src={instaImages[0]} alt=""></img></a>
                </div>
                <div className="w-5/6">
                    <a target='_blank' href={instaLinks[1]}><img class="h-auto rounded-lg" src={instaImages[1]} alt=""></img></a>
                </div>
            </div>
            <div class="grid grid-cols-2 ps-7 md:ps-7 pb-5 md:pb-5 pt-5 md:pt-5">
                <div className="w-5/6">
                    <a target='_blank' href={instaLinks[2]}><img class="h-auto rounded-lg" src={instaImages[2]} alt=""></img></a>
                </div>
                <div className="w-5/6">
                    <a target='_blank' href={instaLinks[3]}><img class="h-auto rounded-lg" src={instaImages[3]} alt=""></img></a>
                </div>
            </div>
            <div class="grid grid-cols-2 ps-7 md:ps-7 pb-5 md:pb-5 pt-5 md:pt-5">
                <div className="w-5/6">
                    <a target='_blank' href={instaLinks[4]}><img class="h-auto rounded-lg" src={instaImages[4]} alt=""></img></a>
                </div>
                <div className="w-5/6">
                    <a target='_blank' href={instaLinks[5]}><img class="h-auto rounded-lg" src={instaImages[5]} alt=""></img></a>
                </div>
            </div>
            <div className="pb-2" style={{textAlign: '-webkit-center'}}>
                <button
                    type="button"
                    data-twe-ripple-init
                    data-twe-ripple-color="light"
                    class="flex rounded bg-[#f09433] px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                    style={{background: '-webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)'}}
                >
                    <span className="pe-2" style={{lineHeight: 2.3}}><a target="_blank" href="https://www.instagram.com/flo_churchstreet/">Follow us</a></span>
                    <span class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#ffffff]">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                        </svg>
                    </span>
                </button>
            </div>
        </div>

    );
};

export default InstagramSectionMobile;
