import React from "react";

//React Icons
// import { AlignJustify, X } from "lucide-react";

// import Logo2 from "../assests/logo.png";
import Logo2 from "../assests/new_logo.svg";
// import MobileNav from "./MobileNav";

const Navbar = (props) => {
  return (
    <>
      <nav className="w-full py-3 px-6 md:py-4 md:px-28 flex flex-row items-center justify-between fixed hrefp-0 shadow-sm backdrop-blur-lg  z-50 font-poppins ">
        {/*------------------Logo--------------------------*/}
        <a href={"/"}>
          <div className="cursor-pointer flex flex-col items-center select-none">
            <img src={Logo2} alt="logo" className="w-[80px] md:w-[150px]" />
          </div>
        </a>

        {/*------------------NAV a--------------------------*/}

        <div className="flex flex-row items-center space-x-4 lg:space-x-0 lg:items-end justify-between lg:justify-start">
          <a
            className="text-lg bg-[#CCAD54] text-[#142E44] px-8 font-semibold py-1.5 rounded-md "
            href="https://wa.me/7619361780"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us
          </a>
          {/*------------------------Live Portfolio------------------------ */}
        </div>
      </nav>
    </>
  );
};
export default Navbar;
