import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'
import {
  NextButton,
  PrevButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import MondayPic from "../assests/weekly-events/mon.webp"
import TuesdayPic from "../assests/weekly-events/tue.webp"
import WednesdayPic from "../assests/weekly-events/wed.webp"
import ThursdayPic from "../assests/weekly-events/thu.webp"
import FridayPic from "../assests/weekly-events/fri.webp"
import SaturdayPic from "../assests/weekly-events/sat.webp"
import SundayPic from "../assests/weekly-events/sun.webp"

const PhotoGalleryMobileEmblaCarousel = () => {
    const DayIntegerMapping =  {
        1: MondayPic,
        2: TuesdayPic,
        3: WednesdayPic,
        4: ThursdayPic,
        5: FridayPic,
        6: SaturdayPic,
        0: SundayPic
    }

    const todayInteger = new Date().getDay();
    const daysOfWeek = 7;
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    AutoScroll({ playOnInit: true, stopOnInteraction: false, startDelay: 1000, stopOnFocusIn: false, speed: 1 })
  ])
  const [isPlaying, setIsPlaying] = useState(true)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const onButtonAutoplayClick = useCallback(
    (callback) => {
      const autoScroll = emblaApi?.plugins()?.autoScroll
      if (!autoScroll) return

      const resetOrStop =
        autoScroll.options.stopOnInteraction === false
          ? autoScroll.reset
          : autoScroll.stop

      resetOrStop()
      callback()
    },
    [emblaApi]
  )

  const toggleAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    const playOrStop = autoScroll.isPlaying()
      ? autoScroll.stop
      : autoScroll.play
    playOrStop()
  }, [emblaApi])

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    setIsPlaying(autoScroll.isPlaying())
    emblaApi
      .on('autoScroll:play', () => setIsPlaying(true))
      .on('autoScroll:stop', () => setIsPlaying(false))
      .on('reInit', () => setIsPlaying(autoScroll.isPlaying()))
  }, [emblaApi])

  return (
    <>
        <div>
            <div className="w-full mt-6 p-5">
                <img
                    src={DayIntegerMapping[todayInteger]}
                    className="w-full rounded"
                    alt="Image_1"
                />
            </div>
            <div className="embla">
                <div className="embla__viewport" ref={emblaRef}>
                    <div className="embla__container">
                        <div className="embla__slide" key={1}>
                            <div className="flex w-full h-full">
                                <img
                                    src={DayIntegerMapping[(todayInteger + 1) % daysOfWeek]}
                                    className="object-contain w-1/2 h-full rounded p-1"
                                    alt="Image_1"
                                />
                                <img
                                    src={DayIntegerMapping[(todayInteger + 2) % daysOfWeek]}
                                    className="object-contain w-1/2 h-full rounded p-1"
                                    alt="Image_2"
                                />
                            </div>
                        </div>
                        <div className="embla__slide" key={2}>
                            <div className="flex w-full h-full">
                                <img
                                    src={DayIntegerMapping[(todayInteger + 3) % daysOfWeek]}
                                    className="object-contain w-1/2 h-full rounded p-1"
                                    alt="Image_1"
                                />
                                <img
                                    src={DayIntegerMapping[(todayInteger + 4) % daysOfWeek]}
                                    className="object-contain w-1/2 h-full rounded p-1"
                                    alt="Image_2"
                                />
                            </div>
                        </div>
                        <div className="embla__slide" key={3}>
                            <div className="flex w-full h-full">
                                <img
                                    src={DayIntegerMapping[(todayInteger + 5) % daysOfWeek]}
                                    className="object-contain w-1/2 h-full rounded p-1"
                                    alt="Image_1"
                                />
                                <img
                                    src={DayIntegerMapping[(todayInteger + 6) % daysOfWeek]}
                                    className="object-contain w-1/2 h-full rounded p-1"
                                    alt="Image_2"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="embla__controls">
                    <div className="embla__buttons">
                    <PrevButton
                        onClick={() => onButtonAutoplayClick(onPrevButtonClick)}
                        disabled={prevBtnDisabled}
                    />
                    <NextButton
                        onClick={() => onButtonAutoplayClick(onNextButtonClick)}
                        disabled={nextBtnDisabled}
                    />
                    </div>

                    <button className="embla__play" onClick={toggleAutoplay} type="button">
                    {isPlaying ? 'Stop' : 'Start'}
                    </button>
                </div> */}
            </div>
        </div>
    </>
  )
}

export default PhotoGalleryMobileEmblaCarousel
