import React from "react";

import FirstPic from "../assests/people/first.webp"
import SecondPic from "../assests/people/second.webp"
import ThirdPic from "../assests/people/third.webp"
import FourthPic from "../assests/people/fourth.webp"
import "./ImageRow.css"

const ImageRow = () => {
  return (
    <div class="row">
        <div class="column">
            <img src={FirstPic} alt="Captured-Moment-1"></img>
        </div>
        <div class="column">
            <img src={SecondPic} alt="Captured-Moment-2"></img>
        </div>
        <div class="column">
            <img src={ThirdPic} alt="Captured-Moment-3"></img>
        </div>
        <div class="column">
            <img src={FourthPic} alt="Captured-Moment-4"></img>
        </div>
    </div>
  );
};

export default ImageRow;
