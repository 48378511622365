import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import styles from "./Main.module.css";
import animation from "./common/animation";
import Navbar from "./Navbar";

// import OurStory from "../assests/huge.svg";
import { ChevronLeft, X, Lock } from "lucide-react";

import Chennai from "../assests/iplTeam/Chennai.png";
import Mumbai from "../assests/iplTeam/mumbai.png";
import Gujrat from "../assests/iplTeam/gujrat.png";
import Rajasthan from "../assests/iplTeam/rajasthan.png";
import Punjab from "../assests/iplTeam/punjab.png";
import Lucknow from "../assests/iplTeam/lucknow.png";
import Hyderabad from "../assests/iplTeam/hyderabad.png";
import Bangalore from "../assests/iplTeam/bangalore.png";
import Delhi from "../assests/iplTeam/Delhi.png";
import Kolkata from "../assests/iplTeam/Kolkata.png";
import MyCarousel from "./carousel";
import Footer from "./Footer";
import CountDownTimer from "./common/countDownTimer";
import CountDownSecond from "./common/CountDownSecond";
import CarouselMobile from "./carousel-mobile";
import AboutUsCarousel from "./AboutUsCarousel";
import AboutUsMobileCarousel from "./AboutUsMobile";
import CapturedMomentsMobile from "./CapturedMomentsMobile";
import ImageRow from "./ImageRow";
import PhotoGallery from "./PhotoGallery";
import PhotoGalleryMobile from "./PhotoGalleryMobile";
import InstagramSectionWeb from "./InstagramSectionWeb";
import InstagramSectionMobile from "./InstagramSectionMobile";
import EnjoyDishesWeb from "./EnjoyDishesWeb";
import EnjoyDishesMobile from "./EnjoyDishesMobile";

const MainPage = () => {
  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  const [openPredictModal, setOpenPredictModal] = useState(false);
  const [selectDay, setSelectDay] = useState("today");

  const today = new Date();
  const formattedToday = moment(today).format("DD/MM/YYYY");

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const formattedTomorrow = moment(tomorrow).format("DD/MM/YYYY");

  const [todayMatchData, setTodayMatchData] = useState();
  const [tomorrowMatchData, setTomorrowMatchData] = useState();
  const getTodayMatchData = () => {
    let data = JSON.stringify({
      date: formattedToday,
    });
    let config = {
      method: "post",
      url: "https://new-dashboard.server.sypto.xyz/api/ipl/sechdule",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setTodayMatchData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTomorrowMatchData = () => {
    let data = JSON.stringify({
      date: formattedTomorrow,
    });
    let config = {
      method: "post",
      url: "https://new-dashboard.server.sypto.xyz/api/ipl/sechdule",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setTomorrowMatchData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTodayMatchData();
    getTomorrowMatchData();
  }, []);

  const [selectMatch, setSelectMatch] = useState("");
  const [selectedMatch, setSelectedMatch] = useState(false);

  const [selectOption, setSelectOption] = useState("");

  const [userDeatilsForm, setUserDetailsForm] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [successPage, setSuccessPage] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validateEmail = () => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!pattern.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const validatePhone = () => {
    if (number.length !== 10) {
      setPhoneError("Phone number should be 10 digits");
    } else {
      setPhoneError("");
    }
  };

  const handleSumbit = () => {
    setIsLoading(true);
    validateEmail();
    validatePhone();

    if (!name || !email || !number) {
      setIsLoading(false);
      setErrorShow(true);
      setError("All Fields are required");
    } else if (emailError || phoneError) {
      setIsLoading(false);
      setErrorShow(true);
      setError("Please fix the validation errors");
    } else {
      let data = JSON.stringify({
        date: moment(today).format("DD/MM/YYYY HH:mm:ss"),
        name: name,
        email: email,
        number: number,
        match_number: selectMatch[0],
        selectedTeam: selectOption,
      });

      let config = {
        method: "post",
        url: "https://new-dashboard.server.sypto.xyz/api/ipl/prediction/match-confirm",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setUserDetailsForm(false);
          setIsLoading(false);
          setSuccessPage(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [errorShow]);

  const [countdownActive, setCountdownActive] = useState(true);
  const [countDownActiveToday, setCountDownActiveToday] = useState(true);
  const [countdownActiveOne, setCountdownActiveOne] = useState(true);
  const [countdownActiveTwo, setCountdownActiveTwo] = useState(true);

  return (
    <div>
      <Navbar setOpenPredictModal={setOpenPredictModal} />

      <div className={`${styles.backgroundHero} smooth-scroll`} id="home">
        <div className={styles.backgroundImage}>
          <div className={styles.overlay}></div>

          <div
            className={`py-48 md:pt-36 px-4  w-full flex items-center justify-center ${styles.textContent}`}
          >
            <div className="md:flex py-6  flex-col space-y-6 hidden items-center justify-center w-[1200px]">
              <div className="flex flex-col space-y-4 items-center justify-center w-full">
                <div className=" text-white text-2xl md:text-4xl font-sans font-medium">
                The best clubbing experience is awaiting for you!{" "}
                  <span className=" text-3xl md:text-6xl font-bold text-[#CCAD54]"></span>
                </div>
                {/* <div className=" text-white text-2xl md:text-4xl font-sans font-medium mt-2 md:mt-4">
                  Stay Tuned!{" "}
                </div> */}
                <div className=" text-3xl md:text-6xl font-sans font-bold mt-2 md:mt-4 text-[#857BFF]"></div>
              </div>
              {/*
              <div className=" text-2xl  text-white font-sans font-bold">
                {" "}
                22<sup className="text-sm">nd</sup> March - 7
                <sup className="text-sm">th</sup> April 2024
              </div>
              */}
              <a
                className="  bg-[#CCAD54] md:text-[20px] md:font-bold py-3 px-6 font-bold text-[#142E44] text-xl font-sans rounded-[6px] cursor-pointer"
                href="https://wa.me/7619361780"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get In Touch
              </a>
            </div>

            <div className="md:hidden px-2">
              <div className=" text-2xl text-center text-white font-medium font-sans leading-[48px]">
              The best clubbing experience is awaiting for you!{" "}
                <span className="text-3xl font-bold font-sans text-[#CCAD54]"></span>
                 {/* Stay Tuned!{" "} */}
              </div>

              {/* <div className=" text-base mt-8 text-white font-sans font-bold text-center">
                {" "}
                22<sup className="text-sm">nd</sup> March - 7
                <sup className="text-sm">th</sup> April 2024
              </div> */}

              {/* <div
                className=" w-full md:w-[50%] bg-[#CCAD54] text-center mt-6 py-3 px-4 font-bold text-[#142E44] text-lg font-sans rounded-lg cursor-pointer"
                onClick={() => setOpenPredictModal(true)}
                >
                Predict & Win Offers From Us
              </div> */}
              <div
                className="w-full md:w-[50%] bg-[#CCAD54] text-center mt-6 py-3 px-4 font-bold text-[#142E44] text-lg font-sans rounded-lg cursor-pointer"
                href="https://wa.me/7619361780"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get In Touch
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="weekly-events" className="bg-[#060d13] pt-10 md:py-0  md:pt-16  ">
        <div className=" px-10 mt-2 md:px-4 text-[30px] leading-[32px] md:leading-none md:text-[50px] text-[#CCAD54] font-cormo uppercase font-extrabold text-left md:text-center md:mt-4">
          Events Scheduled
        </div>
        <div className="md:flex hidden">
          <div className={`pt-4 md:px-4 bg-[#060d13] md:w-full text-center`}>
            <a className={`text-lg px-8 mr-2 md:ps-5 font-semibold py-1.5 rounded-md ` + styles.btnOutlineHoverFill} href="https://wa.me/7619361780?text=Add+me+to+the+guest+list" target="_blank" rel="noopener noreferrer" style={{marginRight: '4rem', marginTop: '0.5rem'}}>Join Guest List</a>
          </div>
        </div>
        <div className="md:hidden">
          <div className={`pt-4 ps-10 md:px-4 bg-[#060d13] md:w-full text-left`}>
            <a className={`text-lg bg-[#CCAD54] text-[#142E44] px-8 mr-2 md:ps-5 font-semibold py-1.5 rounded-md`} href="https://wa.me/7619361780?text=Add+me+to+the+guest+list" target="_blank" rel="noopener noreferrer" style={{marginRight: '4rem', marginTop: '0.5rem'}}>Join Guest List</a>
          </div>
        </div>
        {/* <Marquee /> */}
        <div className="md:flex hidden">
          <PhotoGallery />
        </div>
        <div className="md:hidden">
          <PhotoGalleryMobile />
        </div>
      </div>
      <div id="enjoy-dishes" className="bg-[#060d13] pt-10 md:py-0  md:pt-16  ">
        <div className="px-10 mt-2 md:px-4 text-lg md:text-[24px] font-sans font-medium text-[#857BFF] text-left md:text-center">
          Enjoy Your Dishes
        </div>
        <div className=" px-10 mt-2 md:px-4 text-[30px] leading-[32px] md:leading-none md:text-[50px] text-[#CCAD54] font-cormo uppercase font-extrabold text-left md:text-center md:mt-4">
          WELCOME TO THE HEART OF FLO
        </div>
        {/* <Marquee /> */}
        <div className="md:flex hidden">
          {" "}
          <EnjoyDishesWeb />
        </div>
        <div className="md:hidden">
          <EnjoyDishesMobile />
        </div>
      </div>
      <div id="captured-moments" className="bg-[#060d13] pt-10 md:py-0  md:pt-16  ">
        {/* <div className="px-10 mt-2 md:px-4 text-lg md:text-[24px] font-sans font-medium text-[#857BFF] text-left md:text-center">
          Enjoy Your Dishes
        </div> */}
        <div className=" px-10 mt-2 md:px-4 text-[30px] leading-[32px] md:leading-none md:text-[50px] text-[#CCAD54] font-cormo uppercase font-extrabold text-left md:text-center md:mt-4">
          Captured Moments of Joy & Energy
        </div>
        {/* <Marquee /> */}
        {/* <div className="md:flex hidden">
          {" "}
          <MyCarousel />
          </div> */}
        <div className="md:flex hidden">
          <ImageRow />
        </div>
        <div className="md:hidden">
          <CapturedMomentsMobile />
        </div>
      </div>
      <div id="captured-moments" className="bg-[#060d13] pt-10 md:py-0  md:pt-16  ">
        <div className="md:flex hidden">
          <InstagramSectionWeb/>
        </div>
        <div className="md:hidden">
          <InstagramSectionMobile/>
        </div>
      </div>
      {/*
      <div
        id="our-offers"
        className="bg-[#060d13] flex flex-col  md:flex-row md: items-center md:pt-12 smooth-scroll  "
      >
        <div className="hidden md:flex md:w-[50%] md:h-full">
          <img src={OurStory} alt="OurStory" className="w-full" />
        </div>
        <div className="w-full md:w-[50%] py-16 px-8 md:py-16 md:px-12">
          <div className=" text-lg  md:text-[24px] font-sans font-medium text-[#857BFF] ">
            Offer Details
          </div>
          <div className="text-[30px] md:text-[50px] md:mt-4 uppercase font-cormo font-extrabold text-[#CCAD54] ">
            IPL 2024 Predict & Win at FLO!
          </div>
          <div className=" text-md text-left  leading-[24px]  md:text-[18px] md:leading-[30px]  text-[#ABC5DC] font-medium font-sans mt-4">
            Cricket enthusiasts, it’s your time to shine at FLO! Embrace the
            spirit of IPL 2024 with our exclusive ‘Predict & Win’ offer.
            Showcase your cricketing foresight and get rewarded with
            irresistible deals. Here’s how you can join the league of winners:
          </div>

          <div className="flex flex-col space-y-3 mt-4 md:mt-4 ">

            <ul className=" list-disc marker:text-[#448DCB] flex flex-col space-y-1.5  text-[#849AAD] text-sm md:text-[18px] md:leading-[30px] font-medium font-sans px-3 md:px-4">
              <li className="w-[300px] md:w-full">
                Make Your Prediction: Check upcoming IPL matches and select the
                one you want to predict.
              </li>
              <li className="w-[300px] md:w-full">
                Watch & Win: Enjoy the game at FLO; if your guess is right,
                we'll email you an exclusive offer.
              </li>
              <li className="w-[300px] md:w-full">
                Claim Your Prize: Show your winning email at FLO and claim your
                offer.
              </li>
              <li className="w-[300px] md:w-full">
                Repeat: Our ‘Predict & Win’ offer runs throughout the IPL
                season. Keep predicting to keep winning!
              </li>
            </ul>
          </div>

          <div
            className=" w-full text-center md:w-[50%] bg-[#CCAD54]  mt-10 md:mt-6 py-3 px-4 font-bold text-[#142E44] text-xl font-sans rounded-lg cursor-pointer"
            onClick={() => setOpenPredictModal(true)}
          >
            Start Predicting
          </div>
        </div>

        <div className="w-full md:hidden">
          <img src={OurStory} alt="OurStory" className="w-full" />
        </div>
      </div>
      */}
      <div id="about-us" className={`bg-[#060d13] smooth-scroll`}>
        <div className={` pt-16 md:pt-24 px-8 md:px-36 bg-[#060d13]  `}>
          <div className=" text-[30px] text-left md:text-center md:text-[50px] md:mt-4 uppercase font-cormo font-extrabold text-[#CCAD54] ">
            ABOUT US
          </div>
          <div className="text-md text-left md:text-center leading-[24px]  md:text-[18px] md:leading-[32px] md:mt-6  text-[#ABC5DC] font-medium font-sans">
            In the heart of Bengaluru, we take pride in offering a global
            culinary palate along with exquisite beverages, that tantalise your
            taste buds with flavours from around the world. Our menu is a fusion
            of diverse cuisines, meticulously crafted to create a dining
            experience like no other. The ambience is a celebration of modern
            design, blending elegance with a touch of urban chic.
          </div>
        </div>
        <div className="md:flex hidden">
          {" "}
          <AboutUsCarousel />
        </div>
        <div className="md:hidden">
          <AboutUsMobileCarousel />
        </div>

        {/* <div className="flex flex-row justify-center mt-12">
          <a
            className="  bg-[#CCAD54] md:text-[20px] md:font-bold py-3 px-6 font-bold text-[#142E44] text-xl font-sans rounded-[6px] cursor-pointer"
            href="https://wa.me/7619361780"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us
          </a>
        </div> */}
      </div>
      <div id="reach-out-to-us" className={`bg-[#060d13] smooth-scroll md:flex hidden`} >
        <div className="ms-3 mx-3" style={{display: 'flex', borderColor: 'white'}}>
          <div className={`pt-16 pb-8 md:pt-24 px-5 md:px-5 bg-[#060d13] w-1/2 md:w-full`}>
            <div className=" text-[30px] md:text-[50px] md:mt-4 px-12 md:px-12 uppercase font-cormo font-extrabold text-[#CCAD54] ">
              Expand with Us
            </div>
            <div className="text-md text-left md:text-left px-12 md:px-12 leading-[24px] md:text-[18px] md:leading-[32px] md:mt-6  text-[#ABC5DC] font-medium font-sans">
              Interested in bringing the vibrant Flo experience to a new location? Reach out to explore franchise opportunities and let's make magic together!
            </div>
          </div>
          <div className={` pt-16 md:pt-24 px-5 md:px-5 bg-[#060d13] w-1/2 md:w-full`} style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'right'}}>
            <div className="pt-3 text-md text-left md:text-left px-12 md:px-12 leading-[24px] md:text-[18px] md:leading-[32px] md:mt-6  text-[#ABC5DC] font-medium font-sans" style={{paddingRight: '4rem'}}>
              Explore Franchise Opportunities!
              </div>
            <a class="text-lg bg-[#CCAD54] text-[#142E44] px-8 mr-2 font-semibold py-1.5 rounded-md " href="https://wa.me/7619361780" target="_blank" rel="noopener noreferrer" style={{marginRight: '4rem', marginTop: '0.5rem'}}>Let's Partner</a>
          </div>
        </div>
      </div>
      <div id="reach-out-to-us" className={`bg-[#060d13] smooth-scroll md:hidden`} >
        <div className="ms-3 mx-3" style={{borderColor: 'white'}}>
          <div className={`pt-16 pb-8 md:pt-24 px-5 md:px-5 bg-[#060d13] md:w-full`}>
            <div className=" text-[30px] md:text-[50px] md:mt-4 uppercase font-cormo font-extrabold text-[#CCAD54] ">
              Expand with Us
            </div>
            <div className="text-md leading-[24px] md:text-[18px] md:leading-[32px] md:mt-6  text-[#ABC5DC] font-medium font-sans">
            Interested in bringing the vibrant Flo experience to a new location? Reach out to explore franchise opportunities and let's make magic together!
            </div>
          </div>
          <div className={` md:pt-24 px-5 pb-10 md:px-5 bg-[#060d13] md:w-full`}>
            <a class="text-lg bg-[#CCAD54] text-[#142E44] px-8 mr-2 font-semibold py-1.5 rounded-md " href="https://wa.me/7619361780" target="_blank" rel="noopener noreferrer" style={{marginRight: '4rem', marginTop: '0.5rem'}}>Let's Partner</a>
          </div>
        </div>
      </div>

      {/* our Story*/}

      {openPredictModal === true ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-[#060d13] w-full md:w-[35%] rounded-[20px] px-5 py-8 md:px-10 md:py-5 m-2">
            <div className="flex flex-row justify-between ">
              <div
                className="flex flex-row items-center text-xs text-[#ffffff]/50 font-bold hover:text-white cursor-pointer "
                onClick={() => setOpenPredictModal(false)}
              >
                <ChevronLeft className="w-5 h-5 mr-2" />
                Back
              </div>
              <div
                className="flex flex-row items-center text-[#ffffff]/50 font-bold hover:text-[#ff0000] cursor-pointer "
                onClick={() => setOpenPredictModal(false)}
              >
                <X className="w-5 h-5 mr-2" />
              </div>
            </div>

            <div className="text-white text-xl font-sans font-medium mt-4 ml-2">
              Select the Match
            </div>
            <div className="flex flex-row justify-between mt-8 md:mt-4">
              <div
                className={
                  selectDay === "today"
                    ? "bg-[#ffffff] py-2 px-8 text-black font-medium border border-[#000000]/20 rounded-lg cursor-pointer"
                    : "py-2 px-8 text-white border border-[#ffffff]/20 rounded-lg cursor-pointer"
                }
                onClick={() => setSelectDay("today")}
              >
                Today
              </div>
              <div
                className={
                  selectDay === "tomorrow"
                    ? "bg-[#ffffff] py-2 px-8 text-black font-medium border border-[#000000]/20 rounded-lg cursor-pointer"
                    : "py-2 px-8 text-white border border-[#ffffff]/20 rounded-lg cursor-pointer"
                }
                onClick={() => setSelectDay("tomorrow")}
              >
                Tomorrow
              </div>
            </div>

            {selectDay === "tomorrow" ? (
              <div className="">
                {tomorrowMatchData &&
                  tomorrowMatchData.map((ele, i) => {
                    return (
                      <div
                        key={i}
                        className="bg-white mt-4 rounded-lg px-4 py-3"
                      >
                        <div className="text-center font-bold text-base pt-2">
                          {moment(tomorrow).format("ddd DD MMM")}
                        </div>

                        <div className="text-center">
                          Prediction ends in
                          {tomorrowMatchData.length === 2 ? (
                            <div>
                              {i === 1 ? (
                                <CountDownSecond
                                  time="19:45:00"
                                  countdownActive={countdownActiveOne}
                                  setCountdownActive={setCountdownActiveOne}
                                />
                              ) : (
                                <CountDownSecond
                                  time="15:45:00"
                                  countdownActive={countdownActiveTwo}
                                  setCountdownActive={setCountdownActiveTwo}
                                />
                              )}
                            </div>
                          ) : (
                            <CountDownSecond
                              time="19:45:00"
                              countdownActive={countdownActiveOne}
                              setCountdownActive={setCountdownActiveOne}
                            />
                          )}
                        </div>
                        <div className="flex flex-row items-center justify-between">
                          <div>
                            {ele[4].split(" ")[0] === "Punjab" ? (
                              <img src={Punjab} alt="Punjab" width={100} />
                            ) : ele[4].split(" ")[0] === "Chennai" ? (
                              <img src={Chennai} alt="Chennai" width={100} />
                            ) : ele[4].split(" ")[0] === "Mumbai" ? (
                              <img src={Mumbai} alt="Mumbai" width={100} />
                            ) : ele[4].split(" ")[0] === "Rajasthan" ? (
                              <img
                                src={Rajasthan}
                                alt="Rajashtan"
                                width={100}
                              />
                            ) : ele[4].split(" ")[0] === "Sunrisers" ? (
                              <img src={Hyderabad} alt="Hyderabd" width={100} />
                            ) : ele[4].split(" ")[0] === "Gujarat" ? (
                              <img src={Gujrat} alt="Gujrat" width={100} />
                            ) : ele[4].split(" ")[0] === "Kolkata" ? (
                              <img src={Kolkata} alt="Kolkata" width={100} />
                            ) : ele[4].split(" ")[0] === "Delhi" ? (
                              <img src={Delhi} alt="Delhi" width={100} />
                            ) : ele[4].split(" ")[0] === "Lucknow" ? (
                              <img src={Lucknow} alt="Lucknow" width={100} />
                            ) : (
                              <img
                                src={Bangalore}
                                alt="Bangalore"
                                width={100}
                              />
                            )}
                          </div>
                          <div className="text-xl font-sans font-bold">VS</div>
                          <div>
                            {ele[5].split(" ")[0] === "Punjab" ? (
                              <img src={Punjab} alt="Punjab" width={100} />
                            ) : ele[5].split(" ")[0] === "Chennai" ? (
                              <img src={Chennai} alt="Chennai" width={100} />
                            ) : ele[5].split(" ")[0] === "Mumbai" ? (
                              <img src={Mumbai} alt="Mumbai" width={100} />
                            ) : ele[5].split(" ")[0] === "Rajasthan" ? (
                              <img
                                src={Rajasthan}
                                alt="Rajashtan"
                                width={100}
                              />
                            ) : ele[5].split(" ")[0] === "Sunrisers" ? (
                              <img src={Hyderabad} alt="Hyderabd" width={100} />
                            ) : ele[5].split(" ")[0] === "Gujarat" ? (
                              <img src={Gujrat} alt="Gujrat" width={100} />
                            ) : ele[5].split(" ")[0] === "Kolkata" ? (
                              <img src={Kolkata} alt="Kolkata" width={100} />
                            ) : ele[5].split(" ")[0] === "Delhi" ? (
                              <img src={Delhi} alt="Delhi" width={100} />
                            ) : ele[5].split(" ")[0] === "Lucknow" ? (
                              <img src={Lucknow} alt="Lucknow" width={100} />
                            ) : (
                              <img
                                src={Bangalore}
                                alt="Bangalore"
                                width={100}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className=" mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                          onClick={() => {
                            setSelectMatch(ele);
                            setSelectedMatch(true);
                            setOpenPredictModal(false);
                          }}
                        >
                          Select
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="">
                {todayMatchData &&
                  todayMatchData.map((ele, i) => {
                    return (
                      <div
                        key={i}
                        className="bg-white mt-4 rounded-lg px-6 py-3"
                      >
                        <div className="text-center font-bold text-base pt-2">
                          {moment(today).format("ddd DD MMM")}
                        </div>

                        <div className="text-center">
                          {todayMatchData.length === 2 ? (
                            <div>
                              {i === 1 ? (
                                <span>
                                  {countDownActiveToday === true
                                    ? "Prediction ends in"
                                    : "Prediction Time Finished"}
                                </span>
                              ) : (
                                <span>
                                  {countdownActive === true
                                    ? "Prediction ends in"
                                    : "Prediction Time Finished"}
                                </span>
                              )}
                            </div>
                          ) : (
                            <span>
                              {countDownActiveToday === true
                                ? "Prediction ends in"
                                : "Prediction Time Finished"}
                            </span>
                          )}

                          {todayMatchData.length === 2 ? (
                            <div>
                              {i === 1 ? (
                                <CountDownTimer
                                  time="19:45:00"
                                  countdownActive={countDownActiveToday}
                                  setCountdownActive={setCountDownActiveToday}
                                />
                              ) : (
                                <CountDownTimer
                                  time="15:45:00"
                                  countdownActive={countdownActive}
                                  setCountdownActive={setCountdownActive}
                                />
                              )}
                            </div>
                          ) : (
                            <CountDownTimer
                              time="19:45:00"
                              countdownActive={countDownActiveToday}
                              setCountdownActive={setCountDownActiveToday}
                            />
                          )}
                        </div>
                        <div className="flex flex-row items-center justify-between">
                          <div>
                            {ele[4].split(" ")[0] === "Punjab" ? (
                              <img src={Punjab} alt="Punjab" width={100} />
                            ) : ele[4].split(" ")[0] === "Chennai" ? (
                              <img src={Chennai} alt="Chennai" width={100} />
                            ) : ele[4].split(" ")[0] === "Mumbai" ? (
                              <img src={Mumbai} alt="Mumbai" width={100} />
                            ) : ele[4].split(" ")[0] === "Rajasthan" ? (
                              <img
                                src={Rajasthan}
                                alt="Rajashtan"
                                width={100}
                              />
                            ) : ele[4].split(" ")[0] === "Sunrisers" ? (
                              <img src={Hyderabad} alt="Hyderabd" width={100} />
                            ) : ele[4].split(" ")[0] === "Gujarat" ? (
                              <img src={Gujrat} alt="Gujrat" width={100} />
                            ) : ele[4].split(" ")[0] === "Kolkata" ? (
                              <img src={Kolkata} alt="Kolkata" width={100} />
                            ) : ele[4].split(" ")[0] === "Delhi" ? (
                              <img src={Delhi} alt="Delhi" width={100} />
                            ) : ele[4].split(" ")[0] === "Lucknow" ? (
                              <img src={Lucknow} alt="Lucknow" width={100} />
                            ) : (
                              <img
                                src={Bangalore}
                                alt="Bangalore"
                                width={100}
                              />
                            )}
                          </div>
                          <div className="text-xl font-sans font-bold">VS</div>
                          <div>
                            {ele[5].split(" ")[0] === "Punjab" ? (
                              <img src={Punjab} alt="Punjab" width={100} />
                            ) : ele[5].split(" ")[0] === "Chennai" ? (
                              <img src={Chennai} alt="Chennai" width={100} />
                            ) : ele[5].split(" ")[0] === "Mumbai" ? (
                              <img src={Mumbai} alt="Mumbai" width={100} />
                            ) : ele[5].split(" ")[0] === "Rajasthan" ? (
                              <img
                                src={Rajasthan}
                                alt="Rajashtan"
                                width={100}
                              />
                            ) : ele[5].split(" ")[0] === "Sunrisers" ? (
                              <img src={Hyderabad} alt="Hyderabd" width={100} />
                            ) : ele[5].split(" ")[0] === "Gujarat" ? (
                              <img src={Gujrat} alt="Gujrat" width={100} />
                            ) : ele[5].split(" ")[0] === "Kolkata" ? (
                              <img src={Kolkata} alt="Kolkata" width={100} />
                            ) : ele[5].split(" ")[0] === "Delhi" ? (
                              <img src={Delhi} alt="Delhi" width={100} />
                            ) : ele[5].split(" ")[0] === "Lucknow" ? (
                              <img src={Lucknow} alt="Lucknow" width={100} />
                            ) : (
                              <img
                                src={Bangalore}
                                alt="Bangalore"
                                width={100}
                              />
                            )}
                          </div>
                        </div>
                        {todayMatchData.length === 2 ? (
                          <div>
                            {i === 1 ? (
                              <div>
                                {countDownActiveToday === true ? (
                                  <div
                                    className=" mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                                    onClick={() => {
                                      setSelectMatch(ele);
                                      setSelectedMatch(true);
                                      setOpenPredictModal(false);
                                    }}
                                  >
                                    Select
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      className=" mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                                      disabled
                                    >
                                      <Lock className="mr-2" /> Waiting For
                                      Results!
                                    </div>
                                    <div className="text-xl text-center text-[#800080] font-bold mt-2">
                                      {" "}
                                      Enjoy our dishes
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                {countdownActive === true ? (
                                  <div
                                    className=" mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                                    onClick={() => {
                                      setSelectMatch(ele);
                                      setSelectedMatch(true);
                                      setOpenPredictModal(false);
                                    }}
                                  >
                                    Select
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      className=" mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                                      disabled
                                    >
                                      <Lock className="mr-2" /> Waiting For
                                      Results!
                                    </div>
                                    <div className="text-xl text-center text-[#800080] font-bold mt-2">
                                      {" "}
                                      Enjoy our dishes
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {countDownActiveToday === true ? (
                              <div
                                className=" mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                                onClick={() => {
                                  setSelectMatch(ele);
                                  setSelectedMatch(true);
                                  setOpenPredictModal(false);
                                }}
                              >
                                Select
                              </div>
                            ) : (
                              <div>
                                <div
                                  className=" mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                                  disabled
                                >
                                  <Lock className="mr-2" /> Waiting For Results!
                                </div>
                                <div className="text-xl text-center text-[#800080] font-bold mt-2">
                                  {" "}
                                  Enjoy our dishes
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      ) : null}
      {selectedMatch === true ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-[#060d13] w-full md:w-[35%] rounded-[20px] px-5 py-8 md:px-10 md:py-6 m-2">
            <div className="flex flex-row justify-between ">
              <div
                className="flex flex-row items-center text-[#ffffff]/50 font-bold hover:text-white cursor-pointer "
                onClick={() => {
                  setSelectedMatch(false);
                  setOpenPredictModal(true);
                }}
              >
                <ChevronLeft className="mr-2" />
                Back
              </div>
              <div
                className="flex flex-row items-center text-[#ffffff]/50 font-bold hover:text-[#ff0000] cursor-pointer "
                onClick={() => setSelectedMatch(false)}
              >
                <X className="mr-2" />
              </div>
            </div>
            {selectMatch ? (
              <div className="bg-white mt-8 rounded-lg px-6 py-6 md:px-4 md:py-3">
                <div className="text-center font-bold text-base pt-2">
                  {moment(selectMatch[2], "DD/MM/YYYY").format("ddd DD MMM")}
                </div>

                <div className="flex flex-row items-center justify-between">
                  <div>
                    {selectMatch[4].split(" ")[0] === "Punjab" ? (
                      <img src={Punjab} alt="Punjab" width={100} />
                    ) : selectMatch[4].split(" ")[0] === "Chennai" ? (
                      <img src={Chennai} alt="Chennai" width={100} />
                    ) : selectMatch[4].split(" ")[0] === "Mumbai" ? (
                      <img src={Mumbai} alt="Mumbai" width={100} />
                    ) : selectMatch[4].split(" ")[0] === "Rajasthan" ? (
                      <img src={Rajasthan} alt="Rajashtan" width={100} />
                    ) : selectMatch[4].split(" ")[0] === "Sunrisers" ? (
                      <img src={Hyderabad} alt="Hyderabd" width={100} />
                    ) : selectMatch[4].split(" ")[0] === "Gujarat" ? (
                      <img src={Gujrat} alt="Gujrat" width={100} />
                    ) : selectMatch[4].split(" ")[0] === "Kolkata" ? (
                      <img src={Kolkata} alt="Kolkata" width={100} />
                    ) : selectMatch[4].split(" ")[0] === "Delhi" ? (
                      <img src={Delhi} alt="Delhi" width={100} />
                    ) : selectMatch[4].split(" ")[0] === "Lucknow" ? (
                      <img src={Lucknow} alt="Lucknow" width={100} />
                    ) : (
                      <img src={Bangalore} alt="Bangalore" width={100} />
                    )}
                    <div
                      className={
                        selectOption === `${selectMatch[4]}`
                          ? "text-[18px] bg-[#ffffff] text-[#2959a6] border-[2px] border-[#2959a6]  py-1 mt-3 text-center rounded-full font-medium cursor-pointer"
                          : "text-[18px] text-white  py-1 border bg-[#2959a6] mt-3 text-center rounded-full font-medium cursor-pointer"
                      }
                      onClick={() => setSelectOption(selectMatch[4])}
                    >
                      {selectOption === `${selectMatch[4]}`
                        ? "Selected"
                        : "Select"}
                    </div>
                  </div>
                  <div className="text-xl font-sans font-bold">VS</div>
                  <div>
                    {selectMatch[5].split(" ")[0] === "Punjab" ? (
                      <img src={Punjab} alt="Punjab" width={100} />
                    ) : selectMatch[5].split(" ")[0] === "Chennai" ? (
                      <img src={Chennai} alt="Chennai" width={100} />
                    ) : selectMatch[5].split(" ")[0] === "Mumbai" ? (
                      <img src={Mumbai} alt="Mumbai" width={100} />
                    ) : selectMatch[5].split(" ")[0] === "Rajasthan" ? (
                      <img src={Rajasthan} alt="Rajashtan" width={100} />
                    ) : selectMatch[5].split(" ")[0] === "Sunrisers" ? (
                      <img src={Hyderabad} alt="Hyderabd" width={100} />
                    ) : selectMatch[5].split(" ")[0] === "Gujarat" ? (
                      <img src={Gujrat} alt="Gujrat" width={100} />
                    ) : selectMatch[5].split(" ")[0] === "Kolkata" ? (
                      <img src={Kolkata} alt="Kolkata" width={100} />
                    ) : selectMatch[5].split(" ")[0] === "Delhi" ? (
                      <img src={Delhi} alt="Delhi" width={100} />
                    ) : selectMatch[5].split(" ")[0] === "Lucknow" ? (
                      <img src={Lucknow} alt="Lucknow" width={100} />
                    ) : (
                      <img src={Bangalore} alt="Bangalore" width={100} />
                    )}
                    <div
                      className={
                        selectOption === `${selectMatch[5]}`
                          ? "text-[18px] bg-[#ffffff] text-[#2959a6] border-[2px] border-[#2959a6] py-1 mt-3 text-center rounded-full font-medium cursor-pointer"
                          : "text-[18px] text-white  py-1 border bg-[#2959a6] mt-3 text-center rounded-full font-medium cursor-pointer"
                      }
                      onClick={() => setSelectOption(selectMatch[5])}
                    >
                      {selectOption === `${selectMatch[5]}`
                        ? "Selected"
                        : "Select"}
                    </div>
                  </div>
                </div>
                {(selectOption === `${selectMatch[4]}` ||
                  selectOption === `${selectMatch[5]}`) && (
                  <div
                    className={
                      selectOption
                        ? "mt-6 py-2 bg-[#22b366] text-white rounded-lg font-medium text-center cursor-pointer"
                        : "mt-6 text-center bg-[#CCAD54] text-[#142E44] py-3 px-4 font-bold  text-g font-poppins rounded-lg cursor-pointer"
                    }
                    onClick={() => {
                      setUserDetailsForm(true);
                      setSelectedMatch(false);
                    }}
                  >
                    Confirm Prediction
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {userDeatilsForm === true ? (
        <div className="flex  fixed inset-0  items-center justify-center bg-black bg-opacity-75 z-50">
          <div className=" relative bg-[#060d13] w-full md:w-[35%] rounded-[20px] px-5 py-4 md:px-10 md:py-6 m-2">
            <div
              className="py-3 flex flex-row items-center  text-[#ffffff]/50 font-bold hover:text-white cursor-pointer "
              onClick={() => {
                setUserDetailsForm(false);
                setSelectedMatch(true);
              }}
            >
              <ChevronLeft className="w-5 h-5 mr-2" />
              Back
            </div>

            {errorShow === true ? (
              <div className="py-3 text-[#ff0000]">{error} </div>
            ) : null}
            <div className="text-white text-sm pb-3">
              For Post-Match Results, Provide Your Details
            </div>
            <div className="text-white">Name</div>
            <input
              type="text"
              placeholder="Enter Your name"
              onChange={(e) => setName(e.target.value)}
              className="mt-4 py-3 px-3 w-full rounded-lg"
            />
            <div className="text-white mt-3">Email</div>
            {emailError && <span style={{ color: "red" }}>{emailError}</span>}
            <input
              type="email"
              placeholder="Enter Your email"
              onChange={(e) => setEmail(e.target.value)}
              className="mt-4 py-3 px-3 w-full rounded-lg"
            />
            <div className="mt-3 text-white">Phone Number</div>
            {phoneError && <span style={{ color: "red" }}>{phoneError}</span>}
            <input
              type="number"
              placeholder="Enter your number"
              onChange={(e) => setNumber(e.target.value)}
              className="mt-4 py-3 px-3 w-full rounded-lg"
            />

            <div
              className="mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
              onClick={handleSumbit}
            >
              {isLoading === true ? (
                <span className="flex flex-row justify-center">
                  <svg
                    className="h-10 w-10 text-[#142E44] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              ) : (
                "Submit"
              )}
            </div>
          </div>
        </div>
      ) : null}
      {successPage === true ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-[#ffffff] w-full md:w-[30%] rounded-[20px] px-6 md:px-6 py-6 m-2">
            <div
              className="flex flex-row justify-end items-center text-[#000000]/50 font-bold hover:text-[#ff0000] cursor-pointer "
              onClick={() => setSuccessPage(false)}
            >
              <X className="w-6 h-6 mr-2" /> Close
            </div>

            <div className="text-black font-bold text-center text-xl mt-12">
              Your prediction is locked in!
            </div>
            <div className="text-black text-xl text-center mt-8">
              Now, get ready to enjoy the match at FLO, where the vibe is as
              electrifying as the on-field action.
            </div>

            <div className="text-black text-xl text-center mt-8">
              Why not predict other matches and double the thrill?
            </div>
            <div
              className="mt-6 text-center bg-[#CCAD54] text-[#142E44]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
              onClick={() => {
                setOpenPredictModal(true);
                setSuccessPage(false);
              }}
            >
              Predict Another Match
            </div>
          </div>
        </div>
      ) : null}

      {/* Footer   */}

      <Footer />
    </div>
  );
};

export default MainPage;
