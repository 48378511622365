import React from "react";
import Logo2 from "../assests/new_logo.svg";

import { Smartphone, MapPin } from "lucide-react";
const Footer = () => {
  return (
    <div className="flex flex-col space-y-5 bg-[#060d13] w-full pt-8 pb-8  md:pt-10 md:pb-6 md:px-20">
      <div className="flex items-end justify-between bg-[#060d13] w-full ">
        <div className="flex flex-col items-center lg:items-start space-y-4 px-4 lg:px-0 md:max-w-[450px]">
          <a href={"/"}>
            <div className="cursor-pointer flex flex-col  select-none">
              <img src={Logo2} alt="logo" className="w-[80px] md:w-[150px]" />
            </div>
          </a>
          <p className="flex flex-row text-sm text-center lg:text-left leading-[22px]  md:text-[16px] md:leading-[22px] md:mt-6  text-[#ABC5DC] font-normal font-sans">
            <Smartphone size={18} />{" "}
            <span className="ml-1 md:ml-3">8095188887</span>
          </p>

          <p className="flex flex-row text-sm text-center lg:text-left leading-[22px]  md:text-[16px] md:leading-[22px]  text-[#ABC5DC] font-normal font-sans">
            <MapPin />{" "}
            <span className="ml-1 md:ml-4">
              MSR Building. FLO, #3, Church St. Shanthala Nagar, Ashok Nagar
              Bengaluru, Karnataka 560001{" "}
            </span>
          </p>
        </div>

        <div className="flex">
          <div className="flex space-x-2 md:mr-6 ">
            <a href="#enjoy-dishes">
              <div className="  underline-animation text-lg md:text[20px] font-sans font-medium  px-3 py-1 text-neutral-50 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
                Enjoy dishes
              </div>
            </a>
            <a href="#our-offers">
              <div className="  underline-animation text-lg md:text[20px] font-sans font-medium  px-3 py-1 text-neutral-50 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
                Our Offers
              </div>
            </a>
            <a href="#about-us">
              <div className="  underline-animation text-lg md:text[20px] font-sans font-medium  px-3 py-1 text-neutral-50 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
                About Us
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center md:justify-between bg-[#060d13] w-full md:border-t-[0.5px] border-[#ffffff]/20 md:px-1">
        <p className="text-md text-center lg:text-left leading-[22px]  md:text-[14px] md:leading-[32px] md:mt-6  text-[#ffffff]/40 font-medium font-sans">
          &copy; {new Date().getFullYear()} Flo Church Street
        </p>
      </div>
    </div>
  );
};

export default Footer;
