import React from "react";
// import { Carousel } from "flowbite-react";
// import useEmblaCarousel from 'embla-carousel-react'
import "./EmblaCarousel.css"

// import MondayPic from "../assests/weekly-events/mon.jpg"
// import TuesdayPic from "../assests/weekly-events/tue.jpg"
// import WednesdayPic from "../assests/weekly-events/wed.jpg"
// import ThursdayPic from "../assests/weekly-events/thu.jpg"
// import FridayPic from "../assests/weekly-events/fri.jpg"
// import SaturdayPic from "../assests/weekly-events/sat.jpeg"
// import SundayPic from "../assests/weekly-events/sun.jpg"
import PhotoGalleryMobileEmblaCarousel from "./PhotoGalleryMobileEmblaCarousel";

const PhotoGalleryMobile = () => {
    // const DayIntegerMapping =  {
    //     1: MondayPic,
    //     2: TuesdayPic,
    //     3: WednesdayPic,
    //     4: ThursdayPic,
    //     5: FridayPic,
    //     6: SaturdayPic,
    //     0: SundayPic
    // }

    // const todayInteger = new Date().getDay();
    // const daysOfWeek = 7;

    return (
        <PhotoGalleryMobileEmblaCarousel/>
        // <div className="embla" ref={emblaRef}>
        //     <div className="embla__container">
        //         <div className="embla__slide">
        //             <div className="flex w-full h-full">
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 1) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_1"
        //                 />
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 2) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_2"
        //                 />
        //             </div>
        //         </div>
        //         <div className="embla__slide">
        //             <div className="flex w-full h-full">
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 3) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_1"
        //                 />
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 4) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_2"
        //                 />
        //             </div>
        //         </div>
        //         <div className="embla__slide">
                    // <div className="flex w-full h-full">
                    //     <img
                    //         src={DayIntegerMapping[(todayInteger + 5) % daysOfWeek]}
                    //         className="object-contain w-1/2 h-full rounded p-1"
                    //         alt="Image_1"
                    //     />
                    //     <img
                    //         src={DayIntegerMapping[(todayInteger + 6) % daysOfWeek]}
                    //         className="object-contain w-1/2 h-full rounded p-1"
                    //         alt="Image_2"
                    //     />
                    // </div>
        //         </div>
        //     </div>
        // </div>
        // <div class="m-5">
        //     <div className="h-[600px] w-full mt-6 p-5">
        //         <img
        //             src={DayIntegerMapping[todayInteger]}
        //             className="w-full rounded"
        //             alt="Image_1"
        //         />
        //         <Carousel slideInterval={3000} className="h-40 mt-5" leftControl=" " rightControl=" ">
        //             <div className="flex w-full h-full">
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 1) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_1"
        //                 />
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 2) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_2"
        //                 />
        //             </div>
        //             <div className="flex w-full h-full">
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 3) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_1"
        //                 />
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 4) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_2"
        //                 />
        //             </div>
        //             <div className="flex w-full h-full">
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 5) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_1"
        //                 />
        //                 <img
        //                     src={DayIntegerMapping[(todayInteger + 6) % daysOfWeek]}
        //                     className="object-contain w-1/2 h-full rounded p-1"
        //                     alt="Image_2"
        //                 />
        //             </div>
        //         </Carousel>
        //     </div>
        // </div>

    );
};

export default PhotoGalleryMobile;
