import React, { useState, useEffect } from "react";

const CountDownTimer = ({ time, setCountdownActive, countdownActive }) => {
  const addTimeToToday = (time) => {
    // Get today's date
    const today = new Date();

    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = time.split(":").map(Number);

    // Set the time to today's date
    today.setHours(hours);
    today.setMinutes(minutes);
    today.setSeconds(seconds);

    return today;
  };

  // Example usage
  const targetTime = addTimeToToday(time);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (Object.keys(newTimeLeft).length === 0) {
        setCountdownActive(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    // Parse targetTime into date, hours, minutes, and seconds
    const targetDateTime = new Date(targetTime);
    const targetDay = targetDateTime.getDay();
    const targetHours = targetDateTime.getHours();
    const targetMinutes = targetDateTime.getMinutes();
    const targetSeconds = targetDateTime.getSeconds();

    // Get current time
    const now = new Date();
    const currentDay = now.getDay();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const currentSeconds = now.getSeconds();

    // Calculate time difference
    let differenceDays = targetDay - currentDay;
    let differenceHours = targetHours - currentHours;
    let differenceMinutes = targetMinutes - currentMinutes;
    let differenceSeconds = targetSeconds - currentSeconds;

    // Adjust if the target time is in the past
    if (differenceSeconds < 0) {
      differenceMinutes--;
      differenceSeconds += 60;
    }
    if (differenceMinutes < 0) {
      differenceHours--;
      differenceMinutes += 60;
    }
    if (differenceHours < 0) {
      differenceDays--;
      differenceHours += 24;
    }
    if (differenceDays < 0) {
      // Countdown finished
      return {};
    }

    return {
      days: differenceDays,
      hours: differenceHours,
      minutes: differenceMinutes,
      seconds: differenceSeconds,
    };
  }

  return (
    <div className="">
      {countdownActive && (
        <div>
          {timeLeft.days !== 0 ? <span>{timeLeft.days} Days, </span> : null}
          {timeLeft.hours !== 0 ? <span>{timeLeft.hours} Hours, </span> : null}
          {timeLeft.minutes !== 0 ? (
            <span>{timeLeft.minutes} Minutes, </span>
          ) : null}
          {timeLeft.seconds !== 0 ? (
            <span>{timeLeft.seconds} Seconds </span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CountDownTimer;
