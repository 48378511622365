import React, { useState, useEffect } from "react";

const CountDownSecond = ({ time, setCountdownActive, countdownActive }) => {
  const addTimeToTomorrow = (time) => {
    // Get tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = time.split(":").map(Number);

    // Set the time to tomorrow's date
    tomorrow.setHours(hours);
    tomorrow.setMinutes(minutes);
    tomorrow.setSeconds(seconds);

    return tomorrow;
  };

  // Example usage
  const targetTime = addTimeToTomorrow(time);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      // Check if target time has passed
      const now = new Date();
      if (now >= targetTime) {
        setCountdownActive(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [targetTime, setCountdownActive]);

  function calculateTimeLeft() {
    // Calculate time difference
    const difference = targetTime - new Date();
    if (difference <= 0) {
      // Countdown finished
      return {};
    }

    // Calculate remaining days, hours, minutes, and seconds
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  return (
    <div className="">
      {countdownActive && (
        <div>
          {timeLeft.days !== 0 ? <span>{timeLeft.days} Days, </span> : null}
          {timeLeft.hours !== 0 ? <span>{timeLeft.hours} Hr, </span> : null}
          {timeLeft.minutes !== 0 ? (
            <span>{timeLeft.minutes} Min, </span>
          ) : null}
          {timeLeft.seconds !== 0 ? (
            <span>{timeLeft.seconds} S </span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CountDownSecond;