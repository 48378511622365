import React from "react";
import { Carousel } from "flowbite-react";

import FirstPic from "../assests/people/first.webp"
import SecondPic from "../assests/people/second.webp"
import ThirdPic from "../assests/people/third.webp"
import FourthPic from "../assests/people/fourth.webp"
import CapturedMomentsMobileEmblaCarousel from "./CapturedMomentsMobileEmblaCarousel"

const CapturedMomentsMobile = () => {
  return (
    // <div className="h-[500px] w-full mt-6 ">
    //   <Carousel slideInterval={3000}>
    //     <div className="flex w-full h-full">
    //       <img
    //         src={FirstPic}
    //         style={{aspectRatio: "2/3"}}
    //         className="object-contain w-full h-full"
    //         alt="Image_1"
    //       />
    //     </div>
    //     <div className="flex w-full h-full ">
    //       <img
    //         src={SecondPic}
    //         className="object-contain w-full h-full"
    //         alt="Image_2"
    //       />

    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={ThirdPic}
    //         className="object-contain w-full h-full"
    //         alt="Image_3"
    //       />

    //     </div>
    //     <div className="flex  w-full h-full  ">
    //       <img
    //         src={FourthPic}
    //         className="object-contain w-full h-full"
    //         alt="Image_4"
    //       />
    //     </div>
    //   </Carousel>
    // </div>
    <CapturedMomentsMobileEmblaCarousel/>
  );
};

export default CapturedMomentsMobile;
