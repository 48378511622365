import React from "react";
import { Carousel } from "flowbite-react";
import First from "../assests/aboutUs/one.jpg";
import Second from "../assests/aboutUs/two.jpg";
import Third from "../assests/aboutUs/three.jpg";
import Fourth from "../assests/aboutUs/four.jpg";
import Five from "../assests/aboutUs/five.jpg";
import Six from "../assests/aboutUs/six.jpg";
import Seven from "../assests/aboutUs/seven.jpg";
import Eight from "../assests/aboutUs/eight.jpg";
import Nine from "../assests/aboutUs/nine.jpg";
import Ten from "../assests/aboutUs/ten.jpg";
const AboutUsCarousel = () => {
  return (
    <div className="hidden md:flex md:px-0 md:h-[450px] md:w-full md:mx-auto">
      <Carousel slideInterval={3000} leftControl=" " rightControl=" ">
        <div className="flex md:space-x-6 md:w-full h-full ">
          <img
            src={First}
            className="object-contain w-[33%] h-full"
            alt="Image_1"
          />
          <img
            src={Second}
            className="object-contain w-[33%] h-full"
            alt="Image_2"
          />
          <img
            src={Third}
            className="object-contain w-[33%] h-full"
            alt="Image_3"
          />
        </div>
        <div className="flex md:space-x-6  md:w-full h-full ">
          <img
            src={Fourth}
            className="object-contain w-[33%] h-full"
            alt="Image_2"
          />
          <img
            src={Five}
            className="object-contain w-[33%] h-full"
            alt="Image_3"
          />
          <img
            src={Six}
            className="object-contain w-[33%] h-full"
            alt="Image_4"
          />
        </div>
        <div className="flex md:space-x-6 md:w-full h-full  ">
          <img
            src={Seven}
            className="object-contain w-[33%] h-full"
            alt="Image_3"
          />
          <img
            src={Eight}
            className="object-contain w-[33%] h-full"
            alt="Image_4"
          />
          <img
            src={Nine}
            className="object-contain w-[33%] h-full"
            alt="Image_1"
          />
        </div>
        <div className="flex md:space-x-6 md:w-full h-full  ">
          <img
            src={Ten}
            className="object-contain w-[33%] h-full"
            alt="Image_4"
          />
          <img
            src={Second}
            className="object-contain w-[33%] h-full"
            alt="Image_1"
          />
          <img
            src={Third}
            className="object-contain w-[33%] h-full"
            alt="Image_2"
          />
        </div>
      </Carousel>
    </div>
  );
};

export default AboutUsCarousel;
