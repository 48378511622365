import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'
import {
  NextButton,
  PrevButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import First from "../assests/dishes/first.webp";
import Second from "../assests/dishes/second.webp";
import Third from "../assests/dishes/third.webp";
import Fourth from "../assests/dishes/fourth.webp";

const EnjoyDishesWeb = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    AutoScroll({ playOnInit: true, stopOnInteraction: false, startDelay: 1000, stopOnFocusIn: false, speed: 1 })
  ])
  const [isPlaying, setIsPlaying] = useState(true)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const onButtonAutoplayClick = useCallback(
    (callback) => {
      const autoScroll = emblaApi?.plugins()?.autoScroll
      if (!autoScroll) return

      const resetOrStop =
        autoScroll.options.stopOnInteraction === false
          ? autoScroll.reset
          : autoScroll.stop

      resetOrStop()
      callback()
    },
    [emblaApi]
  )

  const toggleAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    const playOrStop = autoScroll.isPlaying()
      ? autoScroll.stop
      : autoScroll.play
    playOrStop()
  }, [emblaApi])

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    setIsPlaying(autoScroll.isPlaying())
    emblaApi
      .on('autoScroll:play', () => setIsPlaying(true))
      .on('autoScroll:stop', () => setIsPlaying(false))
      .on('reInit', () => setIsPlaying(autoScroll.isPlaying()))
  }, [emblaApi])

  return (
    <>
        <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    <div className="embla__slide" key={1}>
                        <div className ="flex w-50">
                            <img
                                src={First}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_1"
                            />
                            <img
                                src={Second}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_2"
                            />
                            <img
                                src={Third}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_3"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={4}>
                        <div className ="flex w-50">
                            <img
                                src={Fourth}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_4"
                            />
                            <img
                                src={First}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_1"
                            />
                            <img
                                src={Second}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_2"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={3}>
                        <div className ="flex w-50">
                            <img
                                src={Third}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_3"
                            />
                            <img
                                src={Fourth}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_4"
                            />
                            <img
                                src={First}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_1"
                            />
                        </div>
                    </div>
                    <div className="embla__slide" key={2}>
                        <div className ="flex w-50">
                            <img
                                src={Second}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_2"
                            />
                            <img
                                src={Third}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_3"
                            />
                            <img
                                src={Fourth}
                                className="object-contain w-full h-full scale-75"
                                alt="Image_4"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="embla__controls" style={{marginTop: '-5%'}}>
                <div className="embla__buttons">
                <PrevButton
                    onClick={() => onButtonAutoplayClick(onPrevButtonClick)}
                />
                <NextButton
                    onClick={() => onButtonAutoplayClick(onNextButtonClick)}
                    disabled={nextBtnDisabled}
                />
                </div>

                {/* <button className="embla__play" onClick={toggleAutoplay} type="button">
                {isPlaying ? 'Stop' : 'Start'}
                </button> */}
            </div>
        </div>
    </>
  )
}

export default EnjoyDishesWeb
