import React, { useState, useEffect } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";

import Logo2 from "../../assests/new_logo.svg";

import { ChevronDown, ChevronRight } from "lucide-react";
const SendEmail = () => {
  const [allMatch, setAllMatch] = useState();
  const getData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://new-dashboard.server.sypto.xyz/api/ipl`,
    };

    axios
      .request(config)
      .then((response) => {
        setAllMatch(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const [showAllMatch, setShowAllMatch] = useState(false);
  const [selectMatch, setSelectMatch] = useState("");

  const [showSelectMatchWinner, setShowSelecMatchWinner] = useState(false);
  const [selectMatchWinner, setSelectMatchWinner] = useState("");

  const [allWinnerUsersList, setAllWinnerUsersList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const getWinnerUsersList = () => {
    setIsLoading(true);
    let data = JSON.stringify({
      match_number: selectMatch && selectMatch[0],
    });

    let config = {
      method: "post",
      url: "https://new-dashboard.server.sypto.xyz/api/ipl/list/winner-users",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setShow(true);
        setIsLoading(false);
        setAllWinnerUsersList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [allLoosersList, setAllLoosersList] = useState();
  const [isLoaingOne, setIsLoadingOne] = useState(false);
  const [showOne, setShowOne] = useState(false);
  const getLoosersUsersList = () => {
    setIsLoadingOne(true);
    let data = JSON.stringify({
      match_number: selectMatch && selectMatch[0],
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://new-dashboard.server.sypto.xyz/api/ipl/list/loosers-users",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoadingOne(false);
        setShowOne(true);
        setAllLoosersList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [mailSuccess, setMailSuccess] = useState(false);
  const [isLoadingMail, setIsLoadingMail] = useState(false);
  const sendMailToWinUsers = () => {
    setIsLoadingMail(true);
    let data = JSON.stringify({
      match_number: selectMatch && selectMatch[0],
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://new-dashboard.server.sypto.xyz/api/ipl/send-email/list/winner-users",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoadingMail(false);
        setMailSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [mailSuccessOne, setMailSuccessOne] = useState(false);
  const [isLoadingMailOne, setIsLoadingMailOne] = useState(false);
  const sendMailToLossUsers = () => {
    setIsLoadingMailOne(true);
    let data = JSON.stringify({
      match_number: selectMatch && selectMatch[0],
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://new-dashboard.server.sypto.xyz/api/ipl/send-email/list/loosers-users",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoadingMailOne(false);
        setMailSuccessOne(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    const textToCopy = allWinnerUsersList.map((ele) => ele[2]).join(", ");
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 2000); // Reset copy success message after 2 seconds
      })
      .catch((error) => console.error("Error copying text: ", error));
  };

  const [csvData, setCsvData] = useState([]);
  const [showDownloadMe, setShowDownLoadMe] = useState(false);
  const handleDownload = () => {
    const data = [
      ["Name", "Phone Number"], // Header row
      ...allWinnerUsersList.map((ele) => [ele[1], ele[3]]), // Data rows
    ];
    setCsvData(data);
    setShowDownLoadMe(true);
  };

  const [copySuccessNew, setCopySuccessNew] = useState(false);

  const handleCopyNew = () => {
    const textToCopy = allLoosersList.map((ele) => ele[2]).join(", ");
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccessNew(true);
        setTimeout(() => {
          setCopySuccessNew(false);
        }, 2000); // Reset copy success message after 2 seconds
      })
      .catch((error) => console.error("Error copying text: ", error));
  };

  const [newCsvData, setNewCsvData] = useState([]);
  const [showNewDownloadMe, setShowNewDownLoadMe] = useState(false);

  const handleDownloadNew = () => {
    const data = [
      ["Name", "Phone Number"], // Header row
      ...allLoosersList.map((ele) => [ele[1], ele[3]]), // Data rows
    ];
    setNewCsvData(data);
    setShowNewDownLoadMe(true);
  };
  return (
    <div>
      <nav className="w-full py-3 px-6 md:py-4 md:px-28 flex flex-row items-center justify-between fixed hrefp-0 shadow-sm backdrop-blur-lg  z-50 font-poppins ">
        <a href={"/"}>
          <div className="cursor-pointer flex flex-col items-center select-none">
            <img src={Logo2} alt="logo" className="w-[80px] md:w-[150px]" />
          </div>
        </a>
      </nav>
      <div className="bg-[#000000] py-28 min-h-screen px-32">
        <div className="flex flex-row justify-between">
          {/* select match*/}
          <div className="w-[50%] relative">
            <div
              className="flex flex-row  justify-between font-bold py-4 px-4 text-white border border-[#ffffff]/20 cursor-pointer rounded-lg"
              onClick={() => setShowAllMatch(!showAllMatch)}
            >
              {selectMatch ? (
                <span>
                  Match - {selectMatch[0]} , {selectMatch[4]} vs{" "}
                  {selectMatch[5]}
                </span>
              ) : (
                "Select Match"
              )}
              {showAllMatch ? <ChevronRight /> : <ChevronDown />}
            </div>
            {showAllMatch && (
              <div className="absolute right-0 top-13 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 max-h-[450px] overflow-scroll">
                {allMatch &&
                  allMatch.map((ele, i) => {
                    return (
                      <div
                        className="flex items-center px-2 py-2  text-[14px] rounded-lg mx-3 md-3 text-neutral-300  hover:bg-[#CCAD54]/50 hover:text-white/85  ease-linear duration-150 cursor-pointer pl-3 font-medium"
                        key={i}
                        onClick={() => {
                          setSelectMatch(ele);
                          setShowAllMatch(!showAllMatch);
                          setSelectMatchWinner("");
                        }}
                      >
                        <div>
                          Match-{ele[0]}, {ele[4]} vs {ele[5]}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>

          {/* select winner looser*/}
          <div className="w-[30%] relative">
            <div
              className="rounded-lg flex flex-row  justify-between font-bold py-4 px-4 text-white border border-[#ffffff]/20 cursor-pointer"
              onClick={() => setShowSelecMatchWinner(!showSelectMatchWinner)}
            >
              {selectMatchWinner ? (
                <span>{selectMatchWinner}</span>
              ) : (
                "Select Match winner/losser"
              )}
              {showSelectMatchWinner ? <ChevronRight /> : <ChevronDown />}
            </div>
            {showSelectMatchWinner && (
              <div className="absolute right-0 top-13 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 max-h-[450px] overflow-scroll">
                <div
                  className="flex items-center px-2 py-2  text-[14px] rounded-lg mx-3 md-3 text-neutral-300  hover:bg-[#CCAD54]/50 hover:text-white/85  ease-linear duration-150 cursor-pointer pl-3 font-medium"
                  onClick={() => {
                    setSelectMatchWinner("Winner");
                    setShowSelecMatchWinner(!showSelectMatchWinner);
                  }}
                >
                  <div>Winner</div>
                </div>
                <div
                  className="flex items-center px-2 py-2  text-[14px] rounded-lg mx-3 md-3 text-neutral-300  hover:bg-[#CCAD54]/50 hover:text-white/85  ease-linear duration-150 cursor-pointer pl-3 font-medium"
                  onClick={() => {
                    setSelectMatchWinner("Loosers");
                    setShowSelecMatchWinner(!showSelectMatchWinner);
                  }}
                >
                  <div>Losser</div>
                </div>
              </div>
            )}
          </div>
        </div>

        {show === true ? (
          <div className="pt-8">
            {allWinnerUsersList.length !== 0 ? (
              <div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <div className="text-xl text-white pb-4">
                      Email List of all Winning Users
                    </div>

                    {allWinnerUsersList &&
                      allWinnerUsersList.map((ele, i) => {
                        return (
                          <div className="text-white " key={i}>
                            <div>{ele[2]},</div>
                          </div>
                        );
                      })}
                    <button
                      onClick={handleCopy}
                      className="mt-8 bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                    >
                      {copySuccess ? (
                        <span style={{ color: "green" }}>
                          Copied to clipboard!
                        </span>
                      ) : (
                        "Copy All Email"
                      )}
                    </button>
                    {mailSuccess === true ? (
                      <div>
                        <div className="text-white text-xl">
                          Mail has been sent successfully sent to all won users
                        </div>
                        <div className="flex flex-row justify-center">
                          <button
                            className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                            onClick={() => {
                              setShow(false);
                              setShowOne(false);
                              setSelectMatch("");
                              setSelectMatchWinner("");
                            }}
                          >
                            {" "}
                            Reset
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="mt-8 bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                        onClick={sendMailToWinUsers}
                      >
                        {isLoadingMail === true ? (
                          <span className="flex flex-row justify-center">
                            <svg
                              className="h-10 w-10 text-[#142E44] animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                        ) : (
                          "Send Mail to all users"
                        )}
                      </button>
                    )}
                  </div>
                  <div>
                    <div className="text-xl text-white pb-4">
                      Name & phone Number List of all
                    </div>

                    {allWinnerUsersList &&
                      allWinnerUsersList.map((ele, i) => {
                        return (
                          <div
                            className="text-white flex flex-row border border-[#ffffff]/20"
                            key={i}
                          >
                            <div className="px-6 py-3 border-r border-[#ffffff]/20">
                              {ele[1]}
                            </div>
                            <div className="px-6 py-3">{ele[3]}</div>
                          </div>
                        );
                      })}
                    {showDownloadMe === true ? (
                      <div className="mt-8 ">
                        <CSVLink
                          data={csvData}
                          filename={"winner_users.csv"}
                          className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg
                        font-bold text-xl mt-8" // Hide the link by default
                          target="_blank"
                        >
                          Download me
                        </CSVLink>
                      </div>
                    ) : (
                      <button
                        className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg
                    font-bold text-xl mt-8"
                        onClick={handleDownload}
                      >
                        {" "}
                        Generate List as Csv
                      </button>
                    )}
                  </div>
                </div>

                <div className="flex flex-row justify-center mt-8">
                  <button
                    className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                    onClick={() => {
                      setShow(false);
                      setShowOne(false);
                      setSelectMatch("");
                      setSelectMatchWinner("");
                    }}
                  >
                    {" "}
                    Reset
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-row justify-center text-xl text-white">
                  No Data Found
                </div>
                <div className="flex flex-row justify-center mt-8">
                  <button
                    className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                    onClick={() => {
                      setShow(false);
                      setShowOne(false);
                      setSelectMatch("");
                      setSelectMatchWinner("");
                    }}
                  >
                    {" "}
                    Reset
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : showOne === true ? (
          <div className="pt-8">
            {allLoosersList.length !== 0 ? (
              <div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    {allLoosersList &&
                      allLoosersList.map((ele, i) => {
                        return (
                          <div className="text-white " key={i}>
                            <div>{ele[2]},</div>
                          </div>
                        );
                      })}
                    <button
                      onClick={handleCopyNew}
                      className="mt-8 bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                    >
                      {copySuccessNew ? (
                        <span style={{ color: "green" }}>
                          Copied to clipboard!
                        </span>
                      ) : (
                        "Copy All Email"
                      )}
                    </button>
                    {mailSuccessOne === true ? (
                      <div>
                        <div className="text-white text-xl">
                          Mail has been sent successfully sent to all loss users
                        </div>
                        <div className="flex flex-row justify-center">
                          <button
                            className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                            onClick={() => {
                              setShow(false);
                              setShowOne(false);
                              setSelectMatch("");
                              setSelectMatchWinner("");
                            }}
                          >
                            {" "}
                            Reset
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="mt-8 bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                        onClick={sendMailToLossUsers}
                      >
                        {isLoadingMailOne === true ? (
                          <span className="flex flex-row justify-center">
                            <svg
                              className="h-10 w-10 text-[#142E44] animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                        ) : (
                          "Send Mail to all users"
                        )}
                      </button>
                    )}
                  </div>
                  <div>
                    <div className="text-xl text-white pb-4">
                      Name & phone Number List of all
                    </div>

                    {allLoosersList &&
                      allLoosersList.map((ele, i) => {
                        return (
                          <div
                            className="text-white flex flex-row border border-[#ffffff]/20"
                            key={i}
                          >
                            <div className="px-6 py-3 border-r border-[#ffffff]/20">
                              {ele[1]}
                            </div>
                            <div className="px-6 py-3">{ele[3]}</div>
                          </div>
                        );
                      })}
                    {showNewDownloadMe === true ? (
                      <div className="mt-8 ">
                        <CSVLink
                          data={newCsvData}
                          filename={"loosers_users.csv"}
                          className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg
                    font-bold text-xl mt-8" // Hide the link by default
                          target="_blank"
                        >
                          Download me
                        </CSVLink>
                      </div>
                    ) : (
                      <button
                        className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg
                font-bold text-xl mt-8"
                        onClick={handleDownloadNew}
                      >
                        {" "}
                        Generate List as Csv
                      </button>
                    )}
                  </div>
                </div>

                <div className="flex flex-row justify-center mt-12">
                  <button
                    className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                    onClick={() => {
                      setShow(false);
                      setShowOne(false);
                      setSelectMatch("");
                      setSelectMatchWinner("");
                    }}
                  >
                    {" "}
                    Reset
                  </button>
                </div>
              </div>
            ) : (
              <div className="">
                <div className="flex flex-row justify-center text-xl text-white">
                  No Data Found
                </div>
                <div className="flex flex-row justify-center">
                  <button
                    className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl mt-12"
                    onClick={() => {
                      setShow(false);
                      setShowOne(false);
                      setSelectMatch("");
                      setSelectMatchWinner("");
                    }}
                  >
                    {" "}
                    Reset
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {selectMatchWinner === "Winner" ? (
              <div className="flex flex-row justify-center pt-12">
                {!selectMatch || !selectMatchWinner ? (
                  <button
                    className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl "
                    disabled
                  >
                    Click here to generate result
                  </button>
                ) : (
                  <button
                    className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                    onClick={getWinnerUsersList}
                  >
                    {isLoading === true ? (
                      <span className="flex flex-row justify-center">
                        <svg
                          className="h-10 w-10 text-[#142E44] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      " Click here to generate result"
                    )}
                  </button>
                )}
              </div>
            ) : (
              <div className="flex flex-row justify-center pt-12">
                {!selectMatch || !selectMatchWinner ? (
                  <button
                    className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl "
                    disabled
                  >
                    Click here to generate result
                  </button>
                ) : (
                  <button
                    className="bg-[#CCAD54] px-12 text-white py-3 rounded-lg font-bold text-xl"
                    onClick={getLoosersUsersList}
                  >
                    {isLoaingOne === true ? (
                      <span className="flex flex-row justify-center">
                        <svg
                          className="h-10 w-10 text-[#142E44] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      "Click here to generate result"
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SendEmail;
